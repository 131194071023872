import { Layout, Menu, Tooltip } from "antd";
import logo from "../assets/logo-dark.png";
import { StyledSider } from "../components/styledComponents/styledSider";
import {
  BankOutlined,
  LogoutOutlined,
  SettingOutlined,
  SwapOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { RegistrationRequests } from "../components/registrationRequests";
import { Settings } from "../components/settings";
import { useContext, useState } from "react";
import AuthContext from "../context/AuthProvider";
import { IndividualRequest } from "../components/individualRequest";
import { StyledModal } from "../components/styledComponents/styledModal";
import { TransferRequests } from "../components/transferRequests";
import { IndividualTransfer } from "../components/individualTransfer";
import { AllPlayers } from "../components/allPlayers";
import { IndividualPlayer } from "../components/individualPlayer";
import { Clubs } from "../components/clubs";
import { IndividualClub } from "../components/individualClub";
import { EditPlayer } from "../components/editPlayer";

const { Header, Content, Footer } = Layout;

export const Home = () => {
  const location = useLocation();
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("difaOfficial");
    setAuth({});
    return navigate("/login", { replace: false });
  };

  const logOutDialog = (
    <StyledModal
      title="Confirm Logout"
      visible={modalOpen}
      closable={false}
      destroyOnClose={true}
      cancelText="Cancel"
      okText="Logout"
      okButtonProps={{ type: "danger", ghost: true }}
      onOk={logout}
      onCancel={() => setModalOpen(false)}
    >
      Are you sure you want to logout?
    </StyledModal>
  );

  return (
    <div>
      {logOutDialog}
      <Layout className="home-layout" style={{ minHeight: "100vh" }}>
        <Header
          style={{ display: "flex", alignItems: "center", height: "16%" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <img src={logo} alt="Club Logo" style={{ height: "100px" }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
              }}
            >
              <h1 style={{ color: "white", fontWeight: "bolder" }}>
                DIFA Official
              </h1>
              <Tooltip title="Logout">
                <span
                  style={{
                    cursor: "pointer",
                    fontSize: "x-large",
                    color: "white",
                  }}
                  onClick={() => setModalOpen(true)}
                >
                  <LogoutOutlined />
                </span>
              </Tooltip>
            </div>
          </div>
        </Header>

        <Layout>
          <StyledSider>
            <Menu
              theme="dark"
              defaultSelectedKeys={["/registration-requests"]}
              selectedKeys={[location.pathname]}
              items={[
                {
                  label: (
                    <Link to={"/registration-requests"}>New Registrations</Link>
                  ),
                  key: "/registration-requests",
                  icon: <UsergroupAddOutlined />,
                },
                {
                  label: (
                    <Link to={"/transfer-requests"}>Transfer Requests</Link>
                  ),
                  key: "/transfer-requests",
                  icon: <SwapOutlined />,
                },
                {
                  label: <Link to={"/all-players"}>All Players</Link>,
                  key: "/all-players",
                  icon: <TeamOutlined />,
                },
                {
                  label: <Link to={"/clubs"}>Clubs</Link>,
                  key: "/clubs",
                  icon: <BankOutlined />,
                },
                {
                  label: <Link to={"/settings"}>Settings</Link>,
                  key: "/settings",
                  icon: <SettingOutlined />,
                },
              ]}
            />
          </StyledSider>

          <Content>
            <div>
              <Routes>
                <Route
                  exact
                  path="/registration-requests"
                  element={<RegistrationRequests />}
                />
                <Route
                  exact
                  path="/registration-requests/:registrationNumber"
                  element={<IndividualRequest />}
                />
                <Route
                  exact
                  path="/registration-requests/:registrationNumber/edit"
                  element={<EditPlayer />}
                />

                <Route
                  exact
                  path="/transfer-requests"
                  element={<TransferRequests />}
                />

                <Route
                  exact
                  path="/transfer-requests/:transferId"
                  element={<IndividualTransfer />}
                />

                <Route exact path="/all-players" element={<AllPlayers />} />
                <Route
                  exact
                  path="/all-players/:registrationNumber"
                  element={<IndividualPlayer />}
                />

                <Route exact path="/clubs" element={<Clubs />} />
                <Route
                  exact
                  path="/clubs/:clubId"
                  element={<IndividualClub />}
                />

                <Route exact path="/settings" element={<Settings />} />

                <Route
                  path="*"
                  element={<Navigate to="/registration-requests" replace />}
                />
              </Routes>
            </div>
          </Content>
        </Layout>
        <Footer
          style={{
            backgroundColor: "#00213C",
            color: "white",
            textAlign: "center",
          }}
        >
          © {(new Date()).getFullYear()} DIFA KSA |{" "}
          <b>
            <a
              href="https://teamartizen.com"
              style={{ color: "white", textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Artizen
            </a>
          </b>{" "}
        </Footer>
      </Layout>
    </div>
  );
};
